import * as React from "react";
import GlobalWrapper from "components/core/GlobalWrapper";
import SEO from "components/core/SEO";
import PlainHeader from "components/ui/base/PlainHeader";
import { Trans, useTranslation } from "react-i18next";
import Customers from "components/ui/extended/Customers";
import ImageWithDescription from "components/ui/base/ImageWithDescription";
import FeatureGrid from "components/ui/base/FeatureGrid";
import VideoPreview from "components/ui/base/VideoPreview";
import FullWidthImage from "components/ui/base/FullWidthImage";
import styled from "styled-components";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { REVIEWS_STRUCTURED_DATA } from "constants/seo";
import QuestionsAndAnswers from "components/ui/base/QuestionsAndAnswers";
import RegisterForMarketingAutomation from "components/ui/extended/RegisterForMarketingAutomation";

interface ContactSegmentationPageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        siteUrl: string;
      };
    };
    metaImage: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
  };
  location: Location;
}

export default ({ data, location }: ContactSegmentationPageProps) => {
  const { t } = useTranslation();

  return (
    <GlobalWrapper withLayout location={location}>
      <SEO
        title={t("pages.audience-segmentation.seo.title")}
        description={t("pages.audience-segmentation.seo.description")}
        image={{
          relativePath: "meta/business-intelligence-audience-segmentation.jpg",
          alt: "Business Intelligence Audience Segmentation",
        }}
        structuredData={[
          {
            "@context": "https://schema.org",
            "@type": "Product",
            name: t("pages.audience-segmentation.seo.title"),
            image: [
              data.site.siteMetadata.siteUrl +
                data.metaImage.childImageSharp.gatsbyImageData.images.fallback!
                  .src,
            ],
            description: t("pages.audience-segmentation.seo.description"),
            brand: {
              "@type": "Brand",
              name: "Customerly",
            },
            review: REVIEWS_STRUCTURED_DATA,
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: 4.9,
              reviewCount: 389,
            },
          },
          {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            mainEntity: [1, 2, 3].map((index: number) => {
              return {
                "@type": "Question",
                name: t(`pages.audience-segmentation.faq.${index}.question`),
                acceptedAnswer: {
                  "@type": "Answer",
                  text: t(`pages.audience-segmentation.faq.${index}.answer`),
                },
              };
            }),
          },
        ]}
      />

      <PlainHeader
        title={t("pages.audience-segmentation.title")}
        description={t("pages.audience-segmentation.description")}
        ctaRegister={{ show: true }}
        titleMaxWidth={1000}
        descriptionMaxWidth={650}
        image={{
          relativePath:
            "pages/audience-segmentation/audience-segmentation-lists-and-filters.jpg",
          alt: "Contact list",
        }}
        breadcrumbs={[
          {
            name: "Customerly",
            path: "/",
          },
          {
            name: "Features",
            path: "/features/",
          },
          {
            name: "Audience Segmentation",
            path: "/audience-segmentation/",
          },
        ]}
      />

      <FeatureGrid
        centeredTitle
        mediumImages
        title={t("pages.audience-segmentation.filters.title")}
        sections={Array.from(Array(6).keys()).map((i) => {
          return {
            title: t(`pages.audience-segmentation.filters.${i + 1}.title`),
            description: t(
              `pages.audience-segmentation.filters.${i + 1}.description`
            ),
            image: {
              relativePath: `pages/audience-segmentation/business-intelligence-filters-${
                i + 1
              }.jpg`,
              alt: t(`pages.audience-segmentation.filters.${i + 1}.title`),
            },
          };
        })}
      />

      <VideoPreview
        title={t("pages.audience-segmentation.dynamic-lists.title")}
        paragraph={t("pages.audience-segmentation.dynamic-lists.description")}
        storiesPosition={"left"}
        stories={[
          {
            relativePath: "pages/crm/contact-list-builder.mp4",
          },
        ]}
      />

      <FullWidthImage
        title={t("pages.audience-segmentation.advanced-filters.title")}
        description={
          <Trans i18nKey="pages.audience-segmentation.advanced-filters.description">
            You can mix <GreenSpan>AND</GreenSpan> and <BlueSpan>OR</BlueSpan>{" "}
            conditions to select your customers like a Pro.
          </Trans>
        }
        image={{
          relativePath:
            "pages/audience-segmentation/audience-segmentation-advanced-filters.jpg",
          alt: t("pages.audience-segmentation.advanced-filters.title"),
        }}
      />

      <ImageWithDescription
        title={t("pages.audience-segmentation.filter-example")}
        image={{
          relativePath:
            "pages/audience-segmentation/audience-segmentation-advanced-filter-builder.jpg",
          alt: t("pages.audience-segmentation.filter.title"),
          position: "right",
          fullWidth: true,
        }}
      />

      <FeatureGrid
        title={t("pages.audience-segmentation.best-practices.title")}
        centered={false}
        alternativeColors
        sections={[
          {
            title: t(`pages.audience-segmentation.best-practices.1.title`),
            description: t(
              `pages.audience-segmentation.best-practices.1.description`
            ),
            links: [
              {
                text: t(`pages.audience-segmentation.best-practices.1.cta`),
                path: "/marketing-automation-tool/",
              },
            ],
          },
          {
            title: t(`pages.audience-segmentation.best-practices.2.title`),
            description: t(
              `pages.audience-segmentation.best-practices.2.description`
            ),
            links: [
              {
                text: t(`pages.audience-segmentation.best-practices.2.cta`),
                path: "/marketing-automation-tool/",
              },
            ],
          },
          {
            title: t(`pages.audience-segmentation.best-practices.3.title`),
            description: t(
              `pages.audience-segmentation.best-practices.3.description`
            ),
            links: [
              {
                text: t(`pages.audience-segmentation.best-practices.3.cta`),
                path: "/marketing-automation-tool/",
              },
            ],
          },
          {
            title: t(`pages.audience-segmentation.best-practices.4.title`),
            description: t(
              `pages.audience-segmentation.best-practices.4.description`
            ),
            links: [
              {
                text: t(`pages.audience-segmentation.best-practices.4.cta`),
                path: "/surveys/",
              },
            ],
          },
          {
            title: t(`pages.audience-segmentation.best-practices.5.title`),
            description: t(
              `pages.audience-segmentation.best-practices.5.description`
            ),
            links: [
              {
                text: t(`pages.audience-segmentation.best-practices.5.cta`),
                path: "/net-promoter-score/",
              },
            ],
          },
          {
            title: t(`pages.audience-segmentation.best-practices.6.title`),
            description: t(
              `pages.audience-segmentation.best-practices.6.description`
            ),
            links: [
              {
                text: t(`pages.audience-segmentation.best-practices.6.cta`),
                path: "/email-marketing/",
              },
            ],
          },
        ]}
      />

      <Customers />

      <RegisterForMarketingAutomation />

      <QuestionsAndAnswers
        title={t("pages.audience-segmentation.faq.title")}
        description={t("pages.audience-segmentation.faq.description")}
        elements={[1, 2, 3].map((index) => {
          return {
            question: t(`pages.audience-segmentation.faq.${index}.question`),
            answer: t(`pages.audience-segmentation.faq.${index}.answer`),
          };
        })}
      />
    </GlobalWrapper>
  );
};

const GreenSpan = styled.span`
  color: #62dc36;
  font-weight: bold;
`;

const BlueSpan = styled.span`
  color: #009eff;
  font-weight: bold;
`;
export const contactSegmentationQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    metaImage: file(
      relativePath: {
        eq: "meta/business-intelligence-audience-segmentation.jpg"
      }
      sourceInstanceName: { eq: "images" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
